import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\marine-landing\\node_modules\\next\\dist\\client\\components\\client-page.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\marine-landing\\node_modules\\next\\dist\\client\\components\\client-segment.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\marine-landing\\node_modules\\next\\dist\\client\\components\\error-boundary.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\marine-landing\\node_modules\\next\\dist\\client\\components\\layout-router.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\marine-landing\\node_modules\\next\\dist\\client\\components\\not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\marine-landing\\node_modules\\next\\dist\\client\\components\\render-from-template-context.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Toby2346\\Desktop\\MarineFolder\\marine-landing\\node_modules\\next\\dist\\lib\\metadata\\metadata-boundary.js");
